<template >
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">
                        {{
                            $t("message.edit_m", {
                                m: $t("message.paymentTypes"),
                            })
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
                <div class="timeline-items__right rounded-sm w-100 p-4">
                    <el-form ref="form" :model="form" :rules="rules">
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-form-item
                                prop="name"
                                :label="$t('message.name')"
                                >
                                    <el-input
                                        :placeholder="$t('message.name')"
                                        v-model="form.name"
                                        size="medium"
                                    ></el-input>
                                </el-form-item>

                                <el-form-item>
                                    <span
                                        class="
                                            input--label
                                            d-block
                                            mb-0
                                            line-h-24
                                        "
                                    >
                                        {{ $t("message.status") }}</span
                                    >
                                    <el-select
                                        :value="form.is_active ? form.is_active.id : null"
                                        v-model="form.is_active"
                                        size="medium"
                                        class="w-100"
                                        filterable
                                        clearable
                                    >
                                        <el-option v-for="(status, index) in statuses" :key="'statuses-' + index"
                                            :label="status.name"
                                            :value="status.id"
                                        ></el-option>
                                        
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <!-- end-col -->
                        </el-row>
                    </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    mixins: [form, drawer, show],
    name: "paymentTypes",
    data() {
        return {};
    },
    created() {
        this.getStatuses();
    },
    computed: {
        ...mapGetters({
            rules: "paymentTypes/rules",
            model: "paymentTypes/model",
            statuses: "paymentTypes/statuses",
            columns: "paymentTypes/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "paymentTypes/update",
            show: "paymentTypes/show",
            getStatuses: "paymentTypes/getStatuses",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (res.status == 200) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
